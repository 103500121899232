import { useSessionStore } from "./../stores/session";
import {
  IdentifierOperationType,
  ScalarOperationType,
  ArrayOperationType,
  Gender
} from "@/graphql";
import type { NuxtLinkProps } from "nuxt/app";

import type { Header } from "vue3-easy-data-table";
import WBValueString from "~~/components/WBValueString.vue";
import WBValueAssets from "~~/components/WBValueAssets.vue";
import WBValueDateTime from "~~/components/WBValueDateTime.vue";
import WBValueArray from "~~/components/WBValueArray.vue";
import WBValueBoolean from "~~/components/WBValueBoolean.vue";
import WBValueNumber from "~~/components/WBValueNumber.vue";
import WBValueHash from "~~/components/WBValueHash.vue";
import WBValueRaw from "~~/components/WBValueRaw.vue";
import WBValueFullName from "~~/components/WBValueFullName.vue";
import WBValueStatus from "~~/components/WBValueStatus.vue";
import WBValueInvoiceStatus from "~/components/WBValueInvoiceStatus.vue";
import WBValueClaimStatus from "~/components/WBValueClaimStatus.vue";
import WBValueActions from "~~/components/WBValueActions.vue";
import WBValueCheckbox from "~~/components/WBValueCheckbox.vue";
import WBValuePerson from "~~/components/WBValuePerson.vue";
import WBValueStringClipboard from "~~/components/WBValueStringClipboard.vue";
import WBValueCreditCard from "~~/components/WBValueCreditCard.vue";
import WBValueNavigationLink from "~~/components/WBValueNavigationLink.vue";
import { type Person } from "~/graphql";
import type { ResidenceData } from "~/components/homeContent/types";
import dayjs from "dayjs";

export type SubscriptionInfo = {
  uuid: string;
  name: string;
  logo?: string;
};

export type Develop = "develop" | "qa" | "preprod";
export const developEnvironments: Develop[] = ["develop", "qa", "preprod"];
export type Staging = "staging";
export const stagingEnvironments: Staging[] = ["staging"];

export enum WBView {
  WBValueString = "WBValueString",
  WBValueDateTime = "WBValueDateTime",
  WBValueArray = "WBValueArray",
  WBValueAssets = "WBValueAssets",
  WBValueBoolean = "WBValueBoolean",
  WBValueNumber = "WBValueNumber",
  WBValueHash = "WBValueHash",
  WBValueFullName = "WBValueFullName",
  WBValueStatus = "WBValueStatus",
  WBValueInvoiceStatus = "WBValueInvoiceStatus",
  WBValueClaimStatus = "WBValueClaimStatus",
  WBValueActions = "WBValueActions",
  WBValueCheckbox = "WBValueCheckbox",
  WBValuePerson = "WBValuePerson",
  WBValueStringClipboard = "WBValueStringClipboard",
  WBValueCreditCard = "WBValueCreditCard",
  WBValueNavigationLink = "WBValueStringNavigationLink"
}

//Holds the extra hints that the view component can consider to render the value
type ViewHints = {
  currency?: string; //value of currency
  currencyPath?: string; //path to currency value (eg, policy.oricing.currency)
  hasTimeAgo?: boolean; //considered by WBValueDateTime
  personType?: "policyHolder" | "primaryInsured";
  align?: "left" | "right" | "center";
  width?: number;
  valueLabels?: Array<string>;
  goto?: NuxtLinkProps["to"];
  actions?: any;
  //you can add more view hints here ...
};

//makes another stucture aware of wbview components
type ViewAware = ViewHints & {
  view: WBView;
};

//Similar to EasyDataTable's Header structure for non data table views
type ViewValueBase = {
  label?: string; //as 'text' in Header
  key: string; // as 'value' in Header
};

export type ViewHeader = Header & ViewAware; //for data table
export type ViewValue = ViewValueBase & ViewAware; //for all other

//To be used as props for all view components
export type ViewValueProps<T> = { value: T } & ViewHints;

// APPLICATION PRODUCT START
export enum ProductType {
  GADGET_INSURANCE = "Gadget Insurance",
  HOME_INSURANCE = "Home",
  PERSONAL_CYBER = "Cyber"
}

export type Product = {
  id: string;
  title: string;
  provider?: string;
  type?: ProductType;
  description?: string;
  grossPrice?: number;
  discount?: number;
  expirationDate?: string;
  maxClaimsPerInsurancePeriod?: number | string;
  insurableAmount?: number;
  insurer?: string;
  distributor?: string;
  duration?: string;
  maxNumberOfRenewals?: number | string;
  netPrice?: number;
  insuranceTax?: number;
  policyFee?: number;
};

export type Products = {
  categoryName: string;
  products: Array<Product>;
};

export type SignatureType =
  | { digitalSignature: boolean; physicalSignature?: never }
  | { physicalSignature: boolean; digitalSignature?: never };

export interface ScreenFixData {
  quoteId?: string;
  device: string;
  phoneNumber: string;
  IMEI: string;
  hasSigned: SignatureType | null;
  referrer: string;
}

export interface HomeContentData extends ResidenceData {
  quoteId?: string;
  hasSigned: SignatureType | null;
  referrer: string;
}

export interface PersonalCyberData {
  quoteId?: string;
  hasSigned: SignatureType | null;
  referrer: string;
}

export type ProductData = HomeContentData | ScreenFixData | PersonalCyberData;
export interface Consents {
  consentGDPR: boolean;
  consentExclusiveElectronicCommunication: boolean;
  consentContactInsormation: boolean;
  consentMarketing: boolean;
}

export interface HomeAsset {
  streetName: string;
  streetNumber: string;
  city: string;
}
export interface MobileAsset {
  phone: string;
  IMEI: string;
}

export interface InsuredAsset {
  asset: HomeAsset | MobileAsset | null;
}

export type Account = {
  type: "account" | "card";
  label: string;
  amount: number;
};

export type Frequency = {
  label?: string;
  value?: number;
};

export interface ProductApplication {
  policyId?: string;
  insuredAsset?: InsuredAsset;
  insuredPerson: Person;
  consents: Consents;
  // use array.all on isFinalized to enable/disable payment
  isFinalized: boolean;
  finalConfirmation: boolean;
  isPersonValidated: boolean;
  isAssetValidated: boolean;
  paymentMethod: {
    hasSelectedPaymentMethod: boolean;
    paymentFrequency: Frequency;
    selectedTab: string;
    selectedAccount: Account | null;
    selectedCard: Account | null;
    selectedProvider: string | null;
    linkSentToCustomer: boolean;
    smsChecked: boolean;
    emailChecked: boolean;
    selectedPaymentMethod: "creditCard" | "link" | null;
  };
  signature: SignatureType | null;
}

export interface ProductCartItem {
  product: Product;
  productData: ProductData;
  application?: ProductApplication;
}

type QuestionnaireField = {
  answer: string;
  value: string;
};

// Using Generics here in case other banks have other "type of questions" so it's more free
export type Questionnaire = Record<string, QuestionnaireField>;

export interface NeedsAnalysis {
  questionnaire: Questionnaire;
  signature: SignatureType | null;
  managerApproval: boolean;
}

export interface BankCustomer {
  customerData: Person | null;
  customerAnalysis?: NeedsAnalysis | null;
}

// APPLICATION PRODUCT END

export const ROWS_PER_PAGE = 20;

export const resolveView = (view?: WBView) => {
  switch (view) {
    case WBView.WBValueDateTime:
      return WBValueDateTime;
    case WBView.WBValueArray:
      return WBValueArray;
    case WBView.WBValueBoolean:
      return WBValueBoolean;
    case WBView.WBValueNumber:
      return WBValueNumber;
    case WBView.WBValueHash:
      return WBValueHash;
    case WBView.WBValueString:
      return WBValueString;
    case WBView.WBValueStatus:
      return WBValueStatus;
    case WBView.WBValueFullName:
      return WBValueFullName;
    case WBView.WBValueInvoiceStatus:
      return WBValueInvoiceStatus;
    case WBView.WBValueClaimStatus:
      return WBValueClaimStatus;
    case WBView.WBValueActions:
      return WBValueActions;
    case WBView.WBValueCheckbox:
      return WBValueCheckbox;
    case WBView.WBValuePerson:
      return WBValuePerson;
    case WBView.WBValueStringClipboard:
      return WBValueStringClipboard;
    case WBView.WBValueCreditCard:
      return WBValueCreditCard;
    case WBView.WBValueNavigationLink:
      return WBValueNavigationLink;
    case WBView.WBValueAssets:
      return WBValueAssets;
    default:
      return WBValueRaw;
  }
};

export const inferView = (value: any) => {
  if (isDateTime(value)) {
    return WBValueDateTime;
  } else if (isArray(value)) {
    return WBValueArray;
  } else if (isBoolean(value)) {
    return WBValueBoolean;
  } else if (isNumber(value)) {
    return WBValueNumber;
  }

  return WBValueString;
};

export const getOIDCProviderUrl = () => {
  const { tenant } = useRoute().params;

  const session = useSessionStore();
  if (!session.user?.oidcServerUrl) return;

  let url = session.user.oidcServerUrl;
  if (!url.endsWith("/")) url += "/";
  return `${url}realms/${tenant}/`;
};

export const getSubscriptionName = () => {
  return window.location.hostname.split(".")[0];
};

export const getCurrentEnvironment = () => {
  const parts = location.hostname.split(".");
  const sld = parts[0];
  const enviroment = sld.split("-")[0];

  return enviroment;
};

export const getTenantName = (tenantName: string) => {
  const session = useSessionStore();

  return session.tenant?.name === tenantName;
};

export const getCDNUrl = () => {
  const parts = location.hostname.split(".");
  if (parts.length !== 3) return null;

  let tld = parts[1] + "." + parts[2];
  if (tld.includes("local")) {
    tld = "wallbid.io";
  }

  const env = getCurrentEnvironment();

  if (developEnvironments.includes(env as Develop)) {
    return "https://develop-cdn." + tld;
  } else if ("staging" === env) {
    return "https://staging-cdn." + tld;
  }

  return "https://cdn." + tld;
};

export enum DATA_TYPE {
  STRING,
  INTEGER,
  FLOAT,
  DATE,
  DATETIME,
  BOOLEAN
}

export interface FilterStrategy<T extends operationTypes> {
  buildFilter: (
    value: any,
    operator: T
  ) => {
    fieldName: string;
    filterValue: {
      operation: T;
      elements: Array<any>;
    };
  };

  labels: operationTypes[];
  label?: operationTypes;
}

export enum FILTER_TYPE {
  IDENTIFIER_FILTER = "IDENTIFIER_FILTER",
  SCALAR_FILTER = "SCALAR_FILTER",
  ARRAY_FILTER = "ARRAY_FILTER"
}

export const IdentifierFilter = (
  fieldName: string
): FilterStrategy<IdentifierOperationType> => {
  const buildFilter = (value: string[], operator: IdentifierOperationType) => {
    return {
      fieldName,
      filterValue: {
        operation: operator,
        elements: value
      }
    };
  };

  return {
    buildFilter,
    labels: [
      IdentifierOperationType.Equals,
      IdentifierOperationType.NotEquals,
      IdentifierOperationType.EqualsNull,
      IdentifierOperationType.EqualsNotNull
    ],
    label: IdentifierOperationType.Equals
  };
};

export const ScalarFilter = (
  fieldName: string,
  dataType: DATA_TYPE
): FilterStrategy<ScalarOperationType> => {
  const buildFilter = (value: any[], operator: ScalarOperationType) => {
    const elements = value?.map((e) => {
      if (dataType === DATA_TYPE.DATE || dataType === DATA_TYPE.DATETIME)
        return dayjs(e);
      else return e;
    });
    return {
      fieldName: fieldName,
      filterValue: {
        operation: operator,
        elements: elements
      }
    };
  };

  return {
    buildFilter,
    labels: [
      ScalarOperationType.Equals,
      ScalarOperationType.NotEquals,
      ScalarOperationType.Between,
      ScalarOperationType.GreaterEqualsThan,
      ScalarOperationType.GreaterThan,
      ScalarOperationType.LessEqualsThan,
      ScalarOperationType.LessThan,
      ScalarOperationType.EqualsNull,
      ScalarOperationType.EqualsNotNull
    ]
  };
};

export const ArrayFilter = (
  fieldName: string
): FilterStrategy<ArrayOperationType> => {
  const buildFilter = (value: string[], operator: ArrayOperationType) => {
    return {
      fieldName: fieldName,
      filterValue: {
        operation: operator,
        elements: value
      }
    };
  };
  return {
    buildFilter,
    labels: [ArrayOperationType.Contains, ArrayOperationType.NotContains]
  };
};
export type operationTypes =
  | IdentifierOperationType
  | ScalarOperationType
  | ArrayOperationType;

export const CreateFilter = (
  fieldType: FILTER_TYPE,
  fieldName: string,
  dataType: DATA_TYPE
): FilterStrategy<operationTypes> => {
  let strategy: (fieldName: string, dataType: DATA_TYPE) => any;

  switch (fieldType) {
    case FILTER_TYPE.IDENTIFIER_FILTER:
      strategy = IdentifierFilter;
      break;
    case FILTER_TYPE.SCALAR_FILTER:
      strategy = ScalarFilter;
      break;
    case FILTER_TYPE.ARRAY_FILTER:
      strategy = ArrayFilter;
      break;
  }
  return strategy(fieldName, dataType);
};

export const addFilter = <T>(filterItem: any, filter: T) => {
  let _filter = filterItem.value.getFilter();
  if (
    _filter?.filterValue?.operation &&
    ((_filter?.filterValue?.elements &&
      _filter?.filterValue?.elements.length > 0) ||
      isEmptyOrNotEmptyOperation(_filter?.filterValue?.operation))
  ) {
    (filter as Record<string | number, any>)[_filter.fieldName] =
      _filter.filterValue;
  }
};

export const isEmptyOrNotEmptyOperation = (option: string): boolean => {
  const validOptions: string[] = [
    IdentifierOperationType.EqualsNull,
    ScalarOperationType.EqualsNull,
    IdentifierOperationType.EqualsNotNull,
    ScalarOperationType.EqualsNotNull
  ];
  return validOptions.includes(option);
};

// APPLICATION PRODUCT START
export const isUsingCustomerData = (obj1: any, obj2: any): boolean => {
  return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
};

export const getDiscountValue = (price: number, discount: number): number => {
  return Number(((price * discount) / 100).toFixed(2));
};

export const getCustomerByVAT = (vatId: string) => {
  const policyHolder = policyHolders.find(
    (policyHolder: Person) => vatId === policyHolder.vatId
  );

  return policyHolder ? JSON.parse(JSON.stringify(policyHolder)) : undefined;
};

export function useRetrieveClaimsText(maxClaims: number | string) {
  const getType = typeof maxClaims;
  const claimMsg = maxClaims === 1 ? "claim" : "claims";

  if (getType === "number") {
    return `up to ${maxClaims} ${claimMsg} per insurance period`;
  } else {
    return `${maxClaims} claims`;
  }
}

export function useRetrieveMaxNumberRenewals(renewalNumbers: number | string) {
  const getType = typeof renewalNumbers;
  const renewalMsg = renewalNumbers === 1 ? "renewal" : "renewals";

  if (getType === "number") {
    return `up to ${renewalNumbers} ${renewalMsg} subject to device age`;
  } else {
    return `${renewalNumbers} renewals`;
  }
}

export const policyHolders: Person[] = [
  {
    firstName: "Giorgos",
    lastName: "Papadopoulos",
    dateOfBirth: "1982-12-13",
    gender: Gender.M,
    vatId: "111111111",
    natId: "A11111",
    nationality: "GR",
    email: "stg_testing+customer_1@wallbid.io",
    cellPhoneNo: "6911111111",
    addresses: [
      {
        country: "GR",
        city: "Athens",
        address1: "Chaimanta 27",
        postalCode: "15234",
        floor: 0
      }
    ]
  },
  {
    firstName: "Amalia",
    lastName: "Stamatiou",
    dateOfBirth: "1982-12-13",
    gender: Gender.F,
    vatId: "222222222",
    natId: "A22222",
    nationality: "GR",
    email: "stg_testing+customer_2@wallbid.io",
    cellPhoneNo: "6922222222",
    addresses: [
      {
        country: "GR",
        city: "Athens",
        address1: "Chaimanta 27",
        postalCode: "15234",
        floor: 0
      }
    ]
  },
  {
    firstName: "Eleni",
    lastName: "Florou",
    dateOfBirth: "1982-12-13",
    gender: Gender.F,
    vatId: "333333333",
    natId: "A33333",
    nationality: "GR",
    email: "stg_testing+customer_3@wallbid.io",
    cellPhoneNo: "6933333333",
    addresses: [
      {
        country: "GR",
        city: "Athens",
        address1: "Chaimanta 27",
        postalCode: "15234",
        floor: 0
      }
    ]
  },
  {
    firstName: "Giannis",
    lastName: "Troupis",
    dateOfBirth: "1982-12-13",
    gender: Gender.M,
    vatId: "444444444",
    natId: "A44444",
    nationality: "GR",
    email: "stg_testing+attica_4@wallbid.io",
    cellPhoneNo: "6944444444",
    addresses: [
      {
        country: "GR",
        city: "Athens",
        address1: "Chaimanta 27",
        postalCode: "15234",
        floor: 0
      }
    ]
  }
];

export const products: Products[] = [
  {
    categoryName: "Cyber Insurance",
    products: [
      {
        id: "personalCyber",
        title: "Personal Cyber",
        provider: "",
        type: ProductType.PERSONAL_CYBER,
        description:
          "For secure online transactions, with an emphasis on data protection and security of all transactions.",
        grossPrice: 41.62,
        maxClaimsPerInsurancePeriod: "unlimited/no deductible",
        insurableAmount: 3000,
        insurer: "Ethniki Asfalistiki",
        distributor: "Attica",
        duration: "12 Months",
        maxNumberOfRenewals: "unlimited",
        netPrice: 30.83,
        insuranceTax: 4.62,
        policyFee: 6.17
      }
    ]
  },
  {
    categoryName: "Device Insurance",
    products: [
      {
        id: "screenfix",
        title: "ScreenFix",
        provider: "by",
        type: ProductType.GADGET_INSURANCE,
        description: "Tailored protection for every screen mishap",
        grossPrice: 49.01,
        maxClaimsPerInsurancePeriod: 2,
        insurableAmount: 225,
        insurer: "Secure Horizon Insurance",
        distributor: "Attica",
        duration: "12 Months",
        maxNumberOfRenewals: 3,
        netPrice: 36.3,
        insuranceTax: 5.45,
        policyFee: 7.26
      }
    ]
  },
  {
    categoryName: "Health Insurance",
    products: [
      {
        id: "medicalInsurance",
        title: "Medical Hospitalization Insurance",
        insurer: "Secure Horizon Insurance"
      },
      {
        id: "outpatientInsurance",
        title: "Outpatient Insurance",
        insurer: "Secure Horizon Insurance"
      }
    ]
  },
  {
    categoryName: "Home Insurance",
    products: [
      {
        id: "homeContent",
        title: "Content Premium",
        provider: "",
        type: ProductType.HOME_INSURANCE,
        description: "The ultimate cover for a home, for any risk.",
        grossPrice: 70.04,
        maxClaimsPerInsurancePeriod: "unlimited",
        insurableAmount: 20000,
        insurer: "Secure Horizon Insurance",
        distributor: "Attica",
        duration: "12 Months",
        maxNumberOfRenewals: "unlimited",
        netPrice: 51.88,
        insuranceTax: 7.78,
        policyFee: 10.38
      }
    ]
  },
  {
    categoryName: "Motor Insurance",
    products: [
      {
        id: "motorInsurance",
        title: "Motor Insurance",
        insurer: "Secure Horizon Insurance"
      }
    ]
  },
  {
    categoryName: "Travel Insurance",
    products: [
      {
        id: "cancellationAnyReason",
        title: "Cancellation Insurance for Any Reason",
        insurer: "Secure Horizon Insurance"
      },
      {
        id: "cancellationSpecificReason",
        title: "Cancellation Insurance for Specific Reason",
        insurer: "Secure Horizon Insurance"
      }
    ]
  }
];

// "285741dc-70e7-4185-bd38-9180114f6097" === Attica
// "faeb7471-d5ce-4c04-a0d3-acf65f782b77" === Ergogr
// "fb43f618-a132-4a1b-8912-57ddf791b891" === Nbg
// "d7fc2ef0-ff5f-4f41-8a22-21896c063540" === THE BANK
// "38734770-7a2c-42e1-b287-25594df0e063" === THE TELCO
// "fcae7fe6-96ff-4efb-a8a8-36620f38ea51" === Liberty Group

const insurletMaps = {
  personalCyber: {
    "285741dc-70e7-4185-bd38-9180114f6097":
      "6febeaab-209b-4119-8232-178e24428957",
    "faeb7471-d5ce-4c04-a0d3-acf65f782b77":
      "f1a13946-3c56-4fd2-bf1c-fbf08559cbd8",
    "fb43f618-a132-4a1b-8912-57ddf791b891":
      "e8c7bfcd-e6e3-4eba-a671-9de0be4003e3",
    "d7fc2ef0-ff5f-4f41-8a22-21896c063540":
      "11a3bcb5-df1a-4665-9918-79b803722d44",
    "38734770-7a2c-42e1-b287-25594df0e063":
      "11a3bcb5-df1a-4665-9918-79b803722d44",
    "fcae7fe6-96ff-4efb-a8a8-36620f38ea51":
      "fe1c5908-ffcc-44a0-9a99-f710513d552f",
    "94231ab3-7456-4445-a241-2424707e6805":
      "fe1c5908-ffcc-44a0-9a99-f710513d552f",
    "02ba0de3-1835-421a-b525-55a71a194964":
      "fe1c5908-ffcc-44a0-9a99-f710513d552f"
  },
  screenFix: {
    "285741dc-70e7-4185-bd38-9180114f6097":
      "35ef6ebd-f634-4480-8be7-6bb7c1ecca3d",
    "faeb7471-d5ce-4c04-a0d3-acf65f782b77":
      "ac9e4e97-da46-4009-b644-0ca302a5d086",
    "fb43f618-a132-4a1b-8912-57ddf791b891":
      "3ae88af4-3eea-4f91-9f0c-1194a3b2e57b",
    "d7fc2ef0-ff5f-4f41-8a22-21896c063540":
      "0fa897ae-91b1-4570-874e-9dc906d6994a",
    "38734770-7a2c-42e1-b287-25594df0e063":
      "0fa897ae-91b1-4570-874e-9dc906d6994a",
    "fcae7fe6-96ff-4efb-a8a8-36620f38ea51":
      "af0a2008-8383-4c7f-81c1-3a3c9833c8eb",
    "94231ab3-7456-4445-a241-2424707e6805":
      "af0a2008-8383-4c7f-81c1-3a3c9833c8eb",
    "02ba0de3-1835-421a-b525-55a71a194964":
      "af0a2008-8383-4c7f-81c1-3a3c9833c8eb"
  },
  homeContent: {
    "285741dc-70e7-4185-bd38-9180114f6097":
      "1669cb0d-a3ae-4c04-b916-5f6b19609e5d",
    "faeb7471-d5ce-4c04-a0d3-acf65f782b77":
      "94178ea1-6fcf-42a9-9dd5-e305b732c52c",
    "fb43f618-a132-4a1b-8912-57ddf791b891":
      "cdb85e72-031c-4f5d-80eb-b62c0d465dcc",
    "d7fc2ef0-ff5f-4f41-8a22-21896c063540":
      "3741968e-c833-49d9-9f42-f27bf3ad6da4",
    "38734770-7a2c-42e1-b287-25594df0e063":
      "3741968e-c833-49d9-9f42-f27bf3ad6da4",
    "fcae7fe6-96ff-4efb-a8a8-36620f38ea51":
      "d5070835-43ee-4fa4-8413-328b9957aaae",
    "94231ab3-7456-4445-a241-2424707e6805":
      "d5070835-43ee-4fa4-8413-328b9957aaae",
    "02ba0de3-1835-421a-b525-55a71a194964":
      "d5070835-43ee-4fa4-8413-328b9957aaae"
  }
};

const channelIdMap = {
  "285741dc-70e7-4185-bd38-9180114f6097":
    "bc24f73c-47c9-42d1-abbf-20e929cc9015",
  "faeb7471-d5ce-4c04-a0d3-acf65f782b77":
    "3a56f3ab-b72b-406f-b4d9-14826f8af323",
  "fb43f618-a132-4a1b-8912-57ddf791b891":
    "498fe73d-6fb8-4cbc-b317-125ec0c63b2a",
  "d7fc2ef0-ff5f-4f41-8a22-21896c063540":
    "23cdda2d-08a6-4ead-9348-e118359449cb",
  "38734770-7a2c-42e1-b287-25594df0e063":
    "9727ebd4-a8b4-4fc2-9950-3e5e80d2d2f3",
  "fcae7fe6-96ff-4efb-a8a8-36620f38ea51":
    "065693dc-906f-45fc-989e-8ba956ea4425",
  "94231ab3-7456-4445-a241-2424707e6805":
    "ff068e62-a383-4e48-985e-7d679fc81435",
  "02ba0de3-1835-421a-b525-55a71a194964": "f3b19d17-8b3e-44e4-a36e-9ed68174acbb"
};

export const getInsurletByTenantParam = (
  product: keyof typeof insurletMaps,
  param: string
) => {
  return insurletMaps[product]?.[param as keyof typeof channelIdMap];
};

export const getChanneltByTenantParam = (param: string) => {
  return channelIdMap[param as keyof typeof channelIdMap];
};

// APPLICATION PRODUCT END

export interface CustomerOverview {
  personalInfo: {
    name: string;
    vatId: string;
    email: string;
    cellPhoneNo: string;
  };
  productInfo: {
    model: string;
    serialNo: string;
    IMEI: string;
    estimatedPurchaseDate: string;
  };
  links: {
    coverages: string;
    moreInfo: string;
  };
}

type Field = {
  label: string;
  value: (customer: CustomerOverview) => string;
};

export type Section = {
  tab: string;
  fields: Field[];
};

interface Incident {
  title: string;
  description: string;
  value: boolean;
  otherDescription?: string;
}

type IncidentKeys =
  | "shatteredScreen"
  | "damagedPhoneBody"
  | "phoneNotCharging"
  | "liquidDamage"
  | "unresponsivePhoneScreen"
  | "screenMalfunctioning"
  | "touchscreenNotWorking"
  | "volumeRockerButtonsMalfunctioning"
  | "microphoneMalfunctioning"
  | "speakersMalfunctioning"
  | "otherType";

export type Incidents = Record<IncidentKeys, Incident>;

interface RepairCosts {
  totalRepairCost: number;
  maximumCoverage: number;
  insuredContribution: number;
  claimPayment: number;
}
export interface ClaimAssessment {
  isAccepted: boolean | null;
  payments: RepairCosts;
  decision?: string;
  assessmentState: boolean;
}

export interface Payment {
  hasCustomerAgreed: boolean;
  proofOfPayment: { file: File; previewUrl: string }[];
  paymentState: boolean;
}

export interface Repair {
  isDeviceRepaired: boolean | null;
  proofOfRepair: { file: File; previewUrl: string }[];
  repairState: boolean;
}

export interface DamageInspection {
  isDamageRepairable: boolean;
  repairCost: number;
  estimatedRepairDate: string;
  damageReport: string;
  imagePreviews: Array<{ file: File; previewUrl: string }>;
  damageInspectionState: boolean;
}

export interface ClaimsSubmitter {
  username: string;
  firstName: string;
  lastName: string;
}

export interface Resolution {
  isDeviceDelivered: boolean;
  dateOfResolution: string;
  resolutionState: boolean;
}

// CHART TYPES
export type ChartPeriod = Array<string>;
