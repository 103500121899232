import {
  type Develop,
  developEnvironments,
  getCurrentEnvironment
} from "~/utils";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const env = getCurrentEnvironment();

  // const restrictedURLs = ["tenant-billing", "tenant-claims"];

  // if (
  //   restrictedURLs.includes(to.name as string) &&
  //   !developEnvironments.includes(env as Develop)
  // ) {
  //   return navigateTo({ name: "tenant-policies" });
  // }

  // if (
  //   (to.name as string) === "tenant-new_applications" &&
  //   (!getTenantName("Attica Bank") || !getTenantName("Piraeus Bank"))
  // ) {
  //   return navigateTo({ name: "tenant-policies" });
  // }
});
